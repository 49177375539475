import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  Button,
  Divider,
  Carousel,
  Form,
  Avatar,
  Modal,
  Spin,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import "../../styles/properties.css";
import axios from "axios";
import { isEmpty } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";

export default function PropertyDetails(props) {
  let navigate = useNavigate();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 64,
        color: "#E5BE54",
      }}
      spin
    />
  );

  const [form] = Form.useForm();
  const formRef = useRef(null);
  const param = useParams();
  const { id } = param;
  const [property, setProperty] = useState();
  const [spin, setSpin] = useState(false);
  useEffect(() => {
    details();
  }, []);
  const details = async () => {
    setSpin(true);
    try {
      const response = await axios.get(
        `https://api.sandseekers.ae/api/property/get/${id}`,
        {
          headers: {
            "ngrok-skip-browser-warning": true,
          },
        }
      );
      setProperty(response.data);
      setSpin(false);
    } catch (error) {
      console.error(error);
    }
  };
  const propertyItem = property?.data;
  var propertyPhotos;
  const [disable, setDisable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const onFinish = async (values) => {
    setDisable(true);
    try {
      const response = await axios.post(
        "https://api.sandseekers.ae/api/contact",
        {
          fullName: values?.fullName,
          email: values?.email,
          subject: "interested",
          message: values?.message,
          refNo: propertyItem.ref_no,
        }
      );
      setIsModalVisible(true);
      setApiResponse(response?.data?.data);
      formRef.current.resetFields();
      setDisable(false);
    } catch (err) {
      console.log(err);
    }
  };
  if (propertyItem !== null && propertyItem !== undefined) {
    propertyPhotos = JSON.parse(propertyItem?.photo);
  }
  return (
    <>
      {isEmpty(propertyItem) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "110px",
          }}
        >
          <Spin spinning={spin} size="larger" indicator={antIcon} />
        </div>
      )}

      {!isEmpty(propertyItem) && (
        <>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col span={16} className="Top-left">
              <div style={{ display: "flex" }}>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ display: "flex", width: "770px" }}>
                    <div className="back-screen" onClick={() => navigate(-1)}>
                      <img src="/icons/leftarrowb.png" alt="" />
                      <span style={{ paddingLeft: "10px" }}>back</span>
                    </div>
                  </div>
                  <Carousel
                    dots
                    style={{ width: "770px", height: "500px" }}
                    autoplay={true}
                  >
                    {propertyPhotos.map((image, index) => {
                      return (
                        <div
                          style={{
                            marginTop: "22px",
                            width: "770px",
                            height: "500px",
                          }}
                          key={index}
                        >
                          <img
                            style={{ width: "770px", height: "500px" }}
                            src={`${image}`}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                  <div
                    style={{
                      width: "770px",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "16px",
                    }}
                  >
                    <img
                      className="lower-images"
                      src={`${propertyPhotos[0]}`}
                      alt=""
                    />
                    <img
                      className="lower-images"
                      src={`${propertyPhotos[1]}`}
                      alt=""
                    />
                    <img
                      className="lower-images"
                      src={`${propertyPhotos[2]}`}
                      alt=""
                    />
                    <img
                      className="lower-images"
                      src={`${propertyPhotos[3]}`}
                      alt=""
                    />
                  </div>
                  <div className="s-description">
                    <span className="font-style">{propertyItem.title}</span>
                  </div>
                  <div
                    className="s-description"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ display: "flex" }}>
                      <img src="/icons/bagicon.png" alt="" />
                      <span className="price-detail">
                        AED{" "}
                        {`${propertyItem.price}  ${propertyItem.rental_period}`}
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <img src="/icons/location.svg" alt="" />
                      <span className="location-detail">
                        {propertyItem?.community},{propertyItem?.city}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "770px",
                      marginTop: "20px",
                    }}
                  >
                    <img src="/icons/homeicon.png" alt="" />
                    <span className="details-type">
                      {propertyItem?.property_type} for{" "}
                      {propertyItem?.offering_type}
                    </span>
                  </div>
                  <div className="s-description">
                    <div className="house-details">
                      <div className="box-div">
                        <img src="/icons/bedrect.png" alt="" />
                        <span className="box-text">
                          {propertyItem?.bedroom} Rooms
                        </span>
                      </div>
                      <Divider
                        type="vertical"
                        style={{ width: "2px", color: "black" }}
                      />
                      <div className="box-div">
                        <img src="/icons/bathrect.png" alt="" />
                        <span className="box-text">
                          {propertyItem?.bathroom} baths
                        </span>
                      </div>
                      <Divider
                        type="vertical"
                        style={{ width: "2px", color: "black" }}
                      />

                      <div className="box-div">
                        <img src="/icons/space.png" alt="" />
                        <span className="box-text">{`${propertyItem?.size} SQFT`}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* </Col>
      <Col span={8} style={{paddingLeft:"20px"}}> */}
                <div>
                  <div className="contact-box">
                    <span className="heading-contact">contact us</span>
                    <Form
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={(e) => onFinish(e)}
                      form={form}
                      ref={formRef}
                    >
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a valid email address",
                          },
                        ]}
                      >
                        <Input
                          className="input-styling"
                          placeholder="Email"
                          type="email"
                        />
                      </Form.Item>
                      <Form.Item
                        name="message"
                        rules={[
                          {
                            required: true,
                            message: "Please drop a message",
                          },
                        ]}
                      >
                        <div className="contact-box-content">
                          <Input.TextArea
                            style={{ width: "100%", height: "100%" }}
                            placeholder="Message"
                          />
                        </div>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          className="contact-button"
                          htmlType="submit"
                          disabled={disable}
                        >
                          <span className="button-text">Contact</span>
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                  <div className="agent-box">
                    <span className="agent-text">agent</span>
                    <div className="agent-details">
                      {propertyItem.agent_photo ? (
                        <img
                          src={propertyItem.agent_photo}
                          alt=""
                          style={{
                            marginLeft: "20px",
                            width: "84px",
                            height: "84px",
                            borderRadius: "10px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <Avatar
                          style={{ marginLeft: "20px" }}
                          shape="square"
                          size={84}
                          icon={<UserOutlined />}
                        />
                      )}
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex" }}>
                          <span className="agent-name">
                            {propertyItem.agent_name}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "20px",
                            height: "35px",
                          }}
                        >
                          <img
                            style={{ width: "14px", height: "14px" }}
                            src="/icons/phone1.png"
                            alt=""
                          />
                          <p className="listing-agent-details">
                            {propertyItem.agent_phone}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "20px",
                            height: "30px",
                          }}
                        >
                          <img
                            style={{ width: "14px", height: "14px" }}
                            src="/icons/email1.png"
                            alt=""
                          />
                          <p className="listing-agent-details">
                            {propertyItem.agent_email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              height: "auto",
              marginTop: "20px",
            }}
          >
            <Col span={16}>
              <span className="description-section">DESCRIPTTION</span>
              <div
                dangerouslySetInnerHTML={{
                  __html: propertyItem.description_en,
                }}
              ></div>
            </Col>
          </Row>
        </>
      )}
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        bodyStyle={{
          height: "100px",
          width: "200px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        okButtonProps={{ style: { backgroundColor: "#E5BE54" } }}
      >
        <p style={{ fontSize: "20px" }}>{apiResponse}</p>
      </Modal>
    </>
  );
}
