/** @format */

import React, { useReducer } from "react";
import { Button, Col, Row, Select } from "antd";
import "../../styles/home.css";
import Property from "../../components/Home/Property";
import Feature from "../../components/Home/Feature";
import Team from "../../components/Home/Team";
import HomeContectUs from "../../components/Home/HomeContectUs";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  fetchLocation,
  fetchPriceRange,
  fetchPropertyType,
} from "../../api/home/homeApi";
import { createIntervals } from "../../utils/Helper";
import Header from "../../components/Header";

const { Option } = Select;
const initialState = {
  propertyType: "",
  location: "",
  priceRange: "",
  priceRangeArray: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROPERTY_TYPE":
      return { ...state, propertyTypeValue: action.payload };
    case "SET_LOCATION":
      return { ...state, locationValue: action.payload };
    case "SET_PRICE_RANGE":
      return { ...state, priceRangeValue: action.payload };
    case "SET_PRICE_RANGE_ARRAY":
      return { ...state, priceRangeArray: action.payload };
    default:
      return state;
  }
};

const Home = () => {
  const navigate = useNavigate();
  const [stateData, dispatch] = useReducer(reducer, initialState);
  const { data: locationOption } = useQuery({
    queryKey: ["Location"],
    queryFn: () => {
      return fetchLocation();
    },
    select: (res) => res?.data?.data,
  });

  const { data: propertyType } = useQuery({
    queryKey: ["PropertyType"],
    queryFn: () => {
      return fetchPropertyType();
    },
    select: (res) => res?.data?.data,
  });

  const { data: priceRange } = useQuery({
    queryKey: ["priceRange"],
    queryFn: () => {
      return fetchPriceRange();
    },
    select: (res) => res?.data?.data,
  });
  const handlePriceSelect = async () => {
    const array = createIntervals(priceRange?.min, priceRange?.max);
    dispatch({ type: "SET_PRICE_RANGE_ARRAY", payload: array });
  };
  const handlePropertyTypeChange = (value) => {
    dispatch({ type: "SET_PROPERTY_TYPE", payload: value });
  };
  const handleLocationChange = (value) => {
    dispatch({ type: "SET_LOCATION", payload: value });
  };
  const handlePriceChange = (value) => {
    dispatch({ type: "SET_PRICE_RANGE", payload: value });
  };
  return (
    <div>
      <div className="home-section-1">
        <Header />
        <div className="home-heading-container">
          <p>Find Your Dream Home/Investment</p>
        </div>
        <div className="home-paragraph-container">
          <p>
            Welcome to our real estate website! Whether you're looking to buy,
            sell, or rent a property, we're here to help you every step of the
            way.
          </p>
        </div>
        <div className="home-search-container">
          <Row className="home-search-row">
            <Col xs={24} lg={6} className="col-alignment">
              <Select
                onChange={(e) => handlePropertyTypeChange(e)}
                placeholder="Property Type"
                className="property-type"
              >
                {propertyType?.map((option) => (
                  <Option
                    key={option?.property_type}
                    value={option?.property_type}
                  >
                    {option?.property_type}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} lg={6} className="col-alignment">
              <Select
                className="property-type"
                onChange={handleLocationChange}
                placeholder="Location"
              >
                {locationOption?.map((option) => (
                  <Option key={option?.community} value={option?.community}>
                    {option?.community}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} lg={6} className="col-alignment">
              <Select
                placeholder="Price Range"
                onClick={handlePriceSelect}
                onChange={handlePriceChange}
                options={stateData?.priceRangeArray}
                className="property-type"
              />
            </Col>
            <Col xs={24} lg={6} className="col-alignment">
              <Button
                className="property-type search-button-container"
                htmlType="submit"
                onClick={() =>
                  navigate("/properties", {
                    state: {
                      type: stateData?.propertyTypeValue,
                      location: stateData?.locationValue,
                      price: stateData?.priceRangeValue,
                    },
                  })
                }
              >
                Search
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <Property />

      <Feature />

      {/* <Team /> */}

      <HomeContectUs />
    </div>
  );
};

export default Home;
