export const feature_data = [
  {
    image: "/icons/feature-1.png",
    title: "Trusted By Thousands",
    details: "We take great pride in being trusted by thousands of companies.",
  },
  {
    image: "/icons/feature-2.png",
    title: "Wide Range Of Properties",
    details:
      "At our company, we are proud to offer a wide range of properties.",
  },
  {
    image: "/icons/feature-3.png",
    title: "Finance Made Easy",
    details:
      "One of the key ways we have made finance easy is through the development of user-friendly tools.",
  },
  {
    image: "/icons/feature-4.png",
    title: "Life Time Assistance",
    details:
      "Life time assistance is a concept that has gained a lot of attention in recent years",
  },
];

export const team_member_data = [
  {
    name: "Asad ullah Khan",
    image: "/icons/asad.png",
  },
  {
    name: "Numan Arshad",
    image: "/icons/team-9.png",
  },
  {
    name: "Abdul Rehman",
    image: "/icons/team-3.png",
  },
  {
    name: "Imran ul Haq",
    image: "/icons/team-5.png",
  },
  {
    name: "Muhammad Asim",
    image: "/icons/team-6.png",
  },
  {
    name: "Assad Roz",
    image: "/icons/team-7.png",
  },
  {
    name: "Allah Ditta",
    image: "/icons/ALLAHDitta.png",
  },
  {
    name: "Imran Khurshid",
    image: "/icons/ImranKhurshid.png",
  },
  {
    name: "Suresh Girri",
    image: "/icons/team-2.png",
  },
];

export const routes_data = [
  {
    name: "HOME",
    path: "/",
    isRoute: true,
  },
  {
    name: "PROPERTIES",
    path: "#properties",
    isRoute: false,
  },
  // {
  //   name: "OUR AGENTS",
  //   path: "#agents",
  //   isRoute: false,
  // },
  {
    name: "ABOUT US",
    path: "/about-us",
    isRoute: true,
  },
  {
    name: "CONTACT US",
    path: "/contact-us",
    isRoute: true,
  },
];
