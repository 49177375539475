import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Home from "../pages/Home";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Properties from "../components/Properties";
import TermsCondition from "../pages/TermsCondition";
import PropertyDetails from "../pages/PropertyDetails";

export const routes = [
  { path: "/", component: <Home /> },
  { path: "/properties/?", component: <Properties /> },
  { path: "/about-us", component: <AboutUs /> },
  { path: "/contact-us", component: <ContactUs /> },
  { path: "/property-details/:id", component: <PropertyDetails /> },
  { path: "privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-of-conditions", component: <TermsCondition /> },
];
