import { Card, Carousel, Tooltip } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { getRandomImages, truncateString } from "../../utils/Helper";

export const PropertyCard = ({ item }) => {
  const images = JSON.parse(item?.photo);
  const propertyPhotos = getRandomImages(images);

  return (
    <div>
      <Card className="card">
        <Carousel dots autoplay>
          {propertyPhotos?.map((image) => (
            <div key={image} className="property-card-image-text-container">
              <div className="property-card-image-text">
                <p>{item?.offering_type}</p>
              </div>
              <img src={image} alt="propertyImage" className="card-image" />
            </div>
          ))}
        </Carousel>
        <Link to={`/property-details/${item?.id}`}>
          <>
            <Tooltip title={item?.title_en}>
              <p className="card-state-description">
                {truncateString(item?.title_en, 40)}
              </p>
            </Tooltip>
            <p className="card-state-name">AED {item?.price}</p>
            <div className="card-location-container">
              <img src="/icons/location.svg" alt="locationIcon" />
              <p>
                {item?.community},{item?.city}
              </p>
            </div>
            <div className="property-apartments-detail-container">
              <div className="property-beds-container">
                <img src="/icons/bed.svg" alt="RoomIcon" />
                <span>{`${item?.bedroom}  Beds`}</span>
              </div>
              <div className="property-beds-container">
                <img src="/icons/bath.svg" alt="BathIcon" />
                <span>{`${item?.bathroom}  Baths`}</span>
              </div>
              <div className="property-beds-container">
                <img src="/icons/square.svg" alt="SquareAreaIcon" />
                <span>{`${item?.size} SQFT`}</span>
              </div>
            </div>
          </>
        </Link>
      </Card>
    </div>
  );
};
