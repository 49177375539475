/** @format */

import { Divider, Row, Col } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/footer.css";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="footer-divider-container">
        <Divider />
      </div>
      <Row className="footer-main-container">
        <Col xs={24} lg={6} className="footer-logo">
          <div onClick={() => navigate("/")}>
            <img alt="logo" src="/icons/Logo Colored.svg" />
          </div>
        </Col>
        <Col xs={24} lg={4}>
          <div className="footer-listing-alignment">
            <span className="data-heading">Features</span>
            <span className="data" onClick={() => navigate("/")}>
              Home
            </span>
            <span className="data">Blog</span>
            <span onClick={() => navigate("/contact-us")} className="data">
              Contact
            </span>
          </div>
        </Col>
        <Col xs={24} lg={4}>
          <div className="footer-listing-alignment">
            <span className="data-heading">Term and policies</span>
            <span
              onClick={() => navigate("/terms-of-conditions")}
              className="data"
            >
              Terms of servies
            </span>
            <span onClick={() => navigate("/privacy-policy")} className="data">
              Privacy Policy
            </span>
            <span className="data">Security</span>
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className="footer-listing-alignment">
            <span className="data-heading">Contact Us</span>
            <span className="data">Call : 02 627 7156 </span>
            <span className="data">
              Contact Us for any kind of query related to Real Estate.
            </span>
            <span className="data">Email: info@sandseekers.ae</span>
            <span className="data">
              Address: Office 2202, Floor 22, Commercial Tower A, C-88 Building,
              Electra Street, Abu Dhabi
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
