import { createSlice } from "@reduxjs/toolkit";

const initialState = { propertyList: [] };

const propertySlice = createSlice({
  name: "PropertyList",
  initialState,
  reducers: {
    setPropertyList: (state, action) => {
      state.propertyList = action.payload;
    },
  },
});

export const { setPropertyList } = propertySlice.actions;
export default propertySlice.reducer;
