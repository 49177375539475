import { Button } from "antd";
import React from "react";
import "../../styles/home.css";
import { Link } from "react-router-dom";
const HomeContectUs = () => {
  return (
    <div className="home-contect-us-container">
      <div className="home-contect-us-heading-container">
        <p>Find Best Place For Living</p>
      </div>
      <div className="home-contect-us-paragraph-container">
        <p>
          Spend vacations in best hotels and resorts find the great place of
          your choice using different searching options.{" "}
        </p>
      </div>
      <Link to="/contact-us">
        <div className="home-contect-us-button-container">
          <Button>Contact Us</Button>
        </div>
      </Link>
    </div>
  );
};

export default HomeContectUs;
