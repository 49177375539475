import { Col, Divider, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { routes_data } from "../../constants/StaticData";

export const HamMenu = ({ handleHamMenu }) => {
  return (
    <div className="hamMenu-fixed">
      <Row justify={"center"}>
        {routes_data?.map((route) => {
          return (
            <Col span={24} className="ham-alignment" key={route?.name}>
              {route?.isRoute ? (
                <Link
                  to={route?.path}
                  onClick={handleHamMenu}
                  className="link-style"
                >
                  {route?.name}
                </Link>
              ) : (
                <a
                  href={route?.path}
                  onClick={handleHamMenu}
                  className="link-style"
                >
                  {route?.name}
                </a>
              )}
              <Divider />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
