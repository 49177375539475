import React, { useState, useEffect } from "react";
import { Row, Col, Pagination, Spin } from "antd";
import { getApiQueryParams } from "../../utils/Helper";
import "../../styles/properties.css";
import { useLocation } from "react-router-dom";
import Header from "../Header";
import "../../styles/home.css";
import { LoadingOutlined } from "@ant-design/icons";
import { PropertyCard } from "./PropertyCard";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { fetchPropertyList } from "../../api/Property/propertyApi";
import { setPropertyList } from "../../redux/reducers/propertyReducer";

const Properties = () => {
  const dispatch = useDispatch();
  const {
    state: { type, location, price },
  } = useLocation();
  const { propertyList } = useSelector((state) => state?.property);
  const [currentPage, setCurrentPage] = useState(1);
  const priceRange = price?.split("-");
  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * 6;
  const {
    data: property,
    refetch,
    isLoading: spin,
  } = useQuery({
    queryKey: ["Property"],
    queryFn: () =>
      fetchPropertyList({
        url:
          type || price || location
            ? getApiQueryParams(
                startIndex,
                type,
                priceRange[1],
                priceRange[0],
                location
              )
            : null,
        skip: startIndex,
      }),
    select: (res) => res?.data?.data,
  });
  useEffect(() => {
    if (property) {
      dispatch(setPropertyList(property?.properties));
    }
  }, [property, dispatch]);
  useEffect(() => {
    refetch();
  }, [currentPage]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 64,
        color: "#E5BE54",
      }}
      spin
    />
  );
  return (
    <>
      <div className="properties-main-image-container">
        <Header />
        <div className="home-heading-container">
          <p>Properties</p>
        </div>
        <div className="home-paragraph-container">
          <p>
            Discover the Perfect, Profitable Investment Find Your Dream Home!
          </p>
        </div>
      </div>
      <Row justify={"center"}>
        {spin ? (
          <Spin spinning={spin} size="larger" indicator={antIcon} />
        ) : (
          propertyList?.map((item) => {
            return (
              <Col
                xs={24}
                md={12}
                xl={8}
                xxl={7}
                className="col-alignment"
                key={item?.id}
              >
                <PropertyCard item={item} />
              </Col>
            );
          })
        )}
        {propertyList?.length === 0 && <span>No Data Found</span>}
      </Row>
      {propertyList && (
        <Row justify={"center"} className="pagination-alignment">
          <Pagination
            current={currentPage}
            total={property?.count}
            pageSize={6}
            onChange={onPageChange}
            showSizeChanger={false}
            size={"small"}
          />
        </Row>
      )}
    </>
  );
};

export default Properties;
