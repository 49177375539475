/** @format */

import React, { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../styles/home.css";
import { Col, Row } from "antd";
import { HamMenu } from "./HamMenu";
import { routes_data } from "../../constants/StaticData";
const Header = () => {
  const location = useLocation();
  const [hamMenu, setHamMenu] = useState(false);
  const currentRoute = location.pathname;
  const mobileView = useMemo(() => window.innerWidth < 760, []);
  const handleHamMenu = () => {
    setHamMenu(!hamMenu);
  };
  return (
    <>
      <Row className="home-header-container">
        <Col xs={12} md={6}>
          <div className="home-logo">
            <Link to="/">
              {
                <img
                  src={
                    currentRoute.includes("property-details")
                      ? "/icons/Logo Colored.svg"
                      : "/icons/Logo White.svg"
                  }
                  alt="logo"
                  className="logo-image"
                />
              }
            </Link>
          </div>
        </Col>
        <Col xs={12} lg={18}>
          {mobileView ? (
            <div className="mobileView">
              <img
                src="/icons/Hamburger.png"
                className="hamburgerMenu"
                alt="hamburgerMenuIcon"
                onClick={() => setHamMenu(!hamMenu)}
              />
            </div>
          ) : (
            <Row justify={"center"}>
              {routes_data?.map((route) => {
                return (
                  <Col span={3} className="header-alignment" key={route?.name}>
                    {route?.isRoute ? (
                      <Link to={route?.path}>{route?.name}</Link>
                    ) : (
                      <a href={route?.path}>{route?.name}</a>
                    )}
                  </Col>
                );
              })}
            </Row>
          )}
        </Col>
      </Row>
      {hamMenu && <HamMenu handleHamMenu={handleHamMenu} />}
    </>
  );
};

export default Header;
