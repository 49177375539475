import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import { routes } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import store from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./configs/localization/i18n";
function App() {
  const queryClient = new QueryClient();
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
          <Routes>
            <Route>
              {routes?.map((route) => {
                return (
                  <Route
                    key={route?.path}
                    path={route?.path}
                    element={route?.component}
                  />
                );
              })}
            </Route>
          </Routes>
          <Footer />
        </I18nextProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
