/** @format */

import React, { useState, useRef } from "react";
import { Row, Col, Input, Form, Button, Modal } from "antd";
import Header from "../../components/Header";
import "../../styles/contact.css";
import axios from "axios";
export default function ContactUs() {
  const formRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [disable, setDisable] = useState(false);
  const onFinish = async (values) => {
    setDisable(true);
    try {
      const response = await axios.post(
        "https://api.sandseekers.ae/api/contact",
        {
          fullName: values.fullName,
          email: values.email,
          subject: values.subject,
          message: values.message,
        }
      );
      setIsModalVisible(true);
      setApiResponse(response?.data?.data);
      formRef.current.resetFields();
      setDisable(false);
    } catch (err) {
      console.log(err);
    }
  };
  const [form] = Form.useForm();

  return (
    <>
      <Header />
      <Row>
        <Col span={24} className="topContainer">
          <span className="heading">Contact Us</span>
          <span className="sub-heading">
            If you need to get in touch with us, we're here to help. You can
            send us an email using the contact form on our website, and we'll
            respond as quickly as possible
          </span>
        </Col>
      </Row>
      <Row className="bottomContainer">
        <Col span={12} className="bottomLeftContainer">
          <span className="header">Contact Us</span>
          <Form
            form={form}
            name="contact-form"
            initialValues={{
              remember: true,
            }}
            onFinish={(e) => onFinish(e)}
            layout="vertical"
            className="formContainer"
            ref={formRef}
          >
            <Form.Item
              name="FullName"
              rules={[
                {
                  required: true,
                  message: "Please enter your full name",
                },
                {
                  pattern: "^[a-zA-Z ]+$",
                  message: "Please enter only alphabets",
                },
              ]}
            >
              <Input className="form" placeholder="Full Name" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email address",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input className="form" placeholder="Email Address" />
            </Form.Item>

            <Form.Item
              name="subject"
              rules={[
                {
                  required: true,
                  message: "Please enter a subject",
                },
              ]}
            >
              <Input className="form" placeholder="Subject" />
            </Form.Item>

            <Form.Item
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please enter a message",
                },
              ]}
            >
              <Input.TextArea className="form" rows={5} placeholder="Message" />
            </Form.Item>

            <Form.Item>
              <Button
                className="formButton"
                disabled={disable}
                htmlType="submit"
              >
                SEND MESSAGE
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={1}></Col>
        <Col span={11} className="bottomRightContainer">
          <span className="top-text">
            To contact us, simply fill out the form on our website or give us a
            call at the number provided. Our knowledgeable and friendly team is
            ready to assist you with all of your real estate needs, whether
            you're buying, selling, or renting.
          </span>
          <span className="text-header">Sand Seekers Real Estate LLC</span>
          <span className="bottom-text">
            We understand that finding the perfect property can be a daunting
            task, but we're here to make the process as smooth and stress-free
            as possible. So, don't hesitate to reach out to us at any time, and
            we'll be happy to provide you with the information you need to make
            an informed decision.
          </span>
          <span className="end-text">
            Thank you again for considering our real estate services. We look
            forward to hearing from you soon.
          </span>
        </Col>
      </Row>
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
        bodyStyle={{
          height: "100px",
          width: "200px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        okButtonProps={{ style: { backgroundColor: "#E5BE54" } }}
      >
        <p style={{ fontSize: "20px" }}>{apiResponse}</p>
      </Modal>
    </>
  );
}
