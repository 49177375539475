import React from "react";
import "../../styles/home.css";
export const FeatureCard = ({ image, title, details }) => {
  return (
    <div className="feature-card">
      <div className="feature-card-icon-main-container">
        <div className="feature-card-icon-container">
          <img alt="PropertyImage" src={image} />
        </div>
      </div>
      <div className="feature-card-text-container">
        <b>{title} </b>
      </div>
      <div className="feature-card-text-container-2">
        <p>{details}</p>
      </div>
    </div>
  );
};
