import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Button, Col } from "antd";
import "../../styles/home.css";
import { useQuery } from "@tanstack/react-query";
import { fetchPropertyList } from "../../api/Property/propertyApi";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyList } from "../../redux/reducers/propertyReducer";
import { PropertyCard } from "../Properties/PropertyCard";
const Property = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyList } = useSelector((state) => state?.property);
  const { data: propertyArray } = useQuery({
    queryKey: ["Property"],
    queryFn: () => fetchPropertyList({ skip: 0 }),
    select: (res) => res?.data?.data?.properties,
  });
  useEffect(() => {
    if (propertyArray) {
      dispatch(setPropertyList(propertyArray));
    }
  }, [propertyArray, dispatch]);
  return (
    <div id="properties">
      <div className="property-heading-container">
        <p>Our Properties</p>
      </div>
      <div className="property-paragraph-container">
        <p>Our Featured Properties</p>
      </div>
      <Row justify="center" align={"center"}>
        {propertyList?.map((item) => {
          return (
            <Col
              xs={24}
              md={12}
              xl={8}
              xxl={7}
              className="col-alignment"
              key={item?.id}
            >
              <PropertyCard item={item} />
            </Col>
          );
        })}
      </Row>
      <div className="property-view-all-button-conatainer">
        <Button
          onClick={() =>
            navigate("/properties", {
              state: { type: "", location: "", price: "" },
            })
          }
        >
          View All
        </Button>
      </div>
    </div>
  );
};

export default Property;
