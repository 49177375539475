export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const createIntervals = (minVal, maxVal) => {
  const intervalSize = (maxVal - minVal) / 10;
  const intervals = [];
  for (let i = 0; i < 10; i++) {
    const start = minVal + i * intervalSize;
    const end = start + intervalSize;
    intervals.push({
      value: `${start}-${end}`,
      label: `AED ${start}  to AED ${end}`,
    });
  }
  return intervals;
};

export const getRandomImages = (images) => {
  const randomImages = [];
  for (let i = 0; i < 10; i++) {
    var randomIndex = Math.floor(Math.random() * images.length);
    randomImages.push(images[randomIndex]);
    images.splice(randomIndex, 1);
  }
  return randomImages;
};

export const getApiQueryParams = (
  startIndex,
  type,
  maxPrice,
  minPrice,
  locality
) => {
  let str = "";
  if (startIndex >= 0) {
    str = str + `&skip=${startIndex}`;
  }
  if (locality?.length > 0) {
    str = str + `&community=${locality}`;
  }
  if (type?.length > 0) {
    str = str + `&property_type=${type}`;
  }
  if (maxPrice > 0) {
    str = str + `&maxPrice=${maxPrice}`;
  }
  if (minPrice >= 0) {
    str = str + `&minPrice=${minPrice}`;
  }
  return str;
};
