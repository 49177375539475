/** @format */

import React from "react";
import { Row, Col } from "antd";
import Team from "../../components/Home/Team";
import HomeContectUs from "../../components/Home/HomeContectUs";
import "../../styles/about.css";
import { VisionMission } from "../../components/AboutUs/VisionMission";
import Header from "../../components/Header";
const AboutUs = () => {
  return (
    <>
      <Header />
      <Row>
        <Col span={24} className="Container-top">
          <span className="heading-aboutUs">About Us</span>
          <span className="sub-heading">
            Welcome to our real estate website! We are a team of experienced and
            dedicated real estate professionals who are committed to helping our
            clients find the perfect property for their needs.
          </span>
        </Col>
      </Row>
      <VisionMission
        imageClass="first-image"
        contentClass="second-image"
        heading="Our Vision"
        text="At the heart of our beliefs is the understanding that the prosperity and success of our business is intricately linked to the satisfaction and support of our stakeholders. Our unwavering commitment to our clients' best interests is the foundation of our longevity and triumph. Unlike other companies, we do not engage in fleeting interactions; we prioritize cultivating enduring relationships with our clients."
        headingClass="heading-text"
        textClass="Div-text"
      />
      <VisionMission
        imageClass="first-image1"
        contentClass="second-image2"
        heading="Our Mission"
        text="At our real estate website, we believe that the process of buying, selling, or renting a property should be a seamless and hassle-free experience for everyone involved. That's why our mission is to revolutionize the real estate industry by leveraging the latest technology to simplify the process. Our platform is designed to provide you with a comprehensive range of tools and resources that enable you to make informed decisions."
        headingClass="heading-text1"
        textClass="Div-text1"
      />
      {/* <Team /> */}
      <HomeContectUs />
    </>
  );
};
export default AboutUs;
