import React from "react";
import "../../styles/privacyPolicy.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
const TermsCondition = () => {
  let navigate = useNavigate();
  return (
    <div>
      <div
        className="privacy-policy-button-container"
        onClick={() => navigate(-1)}
      >
        <div style={{ display: "flex", width: "70px", paddingLeft: "130px" }}>
          <ArrowLeftOutlined className="back-button-arrow" />
          <p style={{ margin: "auto" }} className="back-button-text">
            Back
          </p>
        </div>
      </div>
      <div className="privacy-policy-heading-container">
        <p>Terms of Services</p>
      </div>
      <div className="privacy-policy-paragraph-container">
        <p>Last Updated April 7th,2023 </p>
      </div>

      <div className="pricavy-policy-text-container">
        <ol>
          <li>
            Introduction Welcome to our real estate website, which is designed
            to help you find and sell properties. By accessing or using our
            services, you agree to comply with these terms of service.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Services Offered Our website provides a platform for property owners
            and buyers to connect and interact with each other. We offer a range
            of services, including property listings, property search, property
            valuation, and property marketing.
          </li>
          <li style={{ paddingTop: "25px" }}>
            User Registration In order to access our services, you may be
            required to register an account with us. You agree to provide
            accurate and complete information when creating your account, and to
            keep your login credentials secure.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Property Listings If you are a property owner, you may use our
            website to list your property for sale or rent. You agree to provide
            accurate and complete information about your property, including
            photographs, descriptions, and pricing information. We reserve the
            right to remove any property listing that violates our terms of
            service.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Property Search If you are a buyer, you may use our website to
            search for properties that meet your criteria. While we strive to
            provide accurate and up-to-date information, we do not guarantee the
            accuracy of the information provided by property owners.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Property Valuation Our website may offer property valuation
            services, which are based on a variety of factors including
            location, size, and condition of the property. While we strive to
            provide accurate valuations, we do not guarantee the accuracy of our
            valuations.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Property Marketing Our website may offer property marketing
            services, which are designed to help property owners promote their
            listings to potential buyers. While we strive to provide effective
            marketing services, we do not guarantee that our services will
            result in a successful sale or rental of the property.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Intellectual Property All content on our website, including text,
            graphics, logos, and images, is the property of our website or our
            licensors and is protected by copyright and other intellectual
            property laws. You may not reproduce, modify, distribute, or
            otherwise use our content without our prior written permission.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Intellectual Property All content on our website, including text,
            graphics, logos, and images, is the property of our website or our
            licensors and is protected by copyright and other intellectual
            property laws. You may not reproduce, modify, distribute, or
            otherwise use our content without our prior written permission.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Intellectual Property All content on our website, including text,
            graphics, logos, and images, is the property of our website or our
            licensors and is protected by copyright and other intellectual
            property laws. You may not reproduce, modify, distribute, or
            otherwise use our content without our prior written permission.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsCondition;
