import React from "react";
import "../../styles/privacyPolicy.css";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
const PrivacyPolicy = () => {
  let navigate = useNavigate();
  return (
    <div>
      <div
        className="privacy-policy-button-container"
        onClick={() => navigate(-1)}
      >
        <div
          style={{
            display: "flex",
            width: "70px",
            paddingLeft: "130px",
            cursor: "pointer",
          }}
        >
          <ArrowLeftOutlined className="back-button-arrow" />
          <p style={{ margin: "auto" }} className="back-button-text">
            Back
          </p>
        </div>
      </div>
      <div className="privacy-policy-heading-container">
        <p>Privacy Policy</p>
      </div>
      <div className="privacy-policy-paragraph-container">
        <p>Last Updated April 7th,2023 </p>
      </div>

      <div className="pricavy-policy-text-container">
        <ol>
          <li>
            We take your privacy seriously and are committed to protecting your
            personal information. This privacy policy explains how we collect,
            use, and protect your personal information when you use our website.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Information We Collect We may collect the following types of
            personal information when you use our website:
            <ul>
              <li style={{ listStyleType: "disc" }}>
                Contact information such as your name, email address, and phone
                number
              </li>
              <li style={{ listStyleType: "disc" }}>
                Property information such as the type of property you are
                interested in, your budget, and your location preferences
              </li>
              <li style={{ listStyleType: "disc" }}>
                Usage information such as the pages you visit on our website,
                the features you use, and the duration of your visit
              </li>
            </ul>
          </li>
          <li style={{ paddingTop: "25px" }}>
            We may share your personal information with third-party service
            providers who help us to provide our services. We require these
            service providers to protect your personal information and to use it
            only for the purposes for which we disclose it to them. We may also
            disclose your personal information if required by law or in response
            to a legal process.
          </li>
          <li style={{ paddingTop: "25px" }}>
            We take reasonable measures to protect your personal information
            from unauthorized access, use, and disclosure. We use
            industry-standard security technologies and procedures to safeguard
            your information. However, no method of transmission over the
            internet or electronic storage is completely secure, and we cannot
            guarantee the absolute security of your information.
          </li>
          <li style={{ paddingTop: "25px" }}>
            We use cookies and other tracking technologies to collect usage
            information and to improve your experience on our website. Cookies
            are small data files that are placed on your device when you visit
            our website. You can disable cookies through your browser settings,
            but this may limit your ability to use certain features of our
            website.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Our website may contain links to third-party websites that are not
            under our control. We are not responsible for the privacy practices
            of these websites, and we recommend that you review their privacy
            policies before providing any personal information.
          </li>
          <li style={{ paddingTop: "25px" }}>
            Our website is not intended for use by children under the age of 13.
            We do not knowingly collect personal information from children under
            the age of 13, and if we become aware that we have collected such
            information, we will promptly delete it.
          </li>
          <li style={{ paddingTop: "25px" }}>
            We reserve the right to modify this privacy policy at any time
            without prior notice. Your continued use of our website after any
            such modifications shall constitute your acceptance of the modified
            privacy policy.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
