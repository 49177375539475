import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {
  locales as enLocales,
  fileNames as enFileNames,
} from "./imports/enLocales";

const init = {
  resources: {
    en: {
      ...enLocales,
    },
  },
  ns: [...enFileNames],
  fallbackNS: [...enFileNames],
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).init(init);

export default i18n;
